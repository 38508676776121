import AADService from "../services/AADService";

class UserInfo {
    constructor(){
        this.aad = new AADService();
    }
    get Username() {
        return this.aad.getUser().profile.name;
    }
    get Email() {
        return this.aad.getUser().profile.upn;
    }
    get Id() {
        return this.aad.getUser().profile.id;
    }
    get Tenant() {
        return this.aad.getUser().profile.tid;
    }
}

export default UserInfo;