// GLOBAL CONSTANTS
// -----------------------------------

export const STORAGE_KEYS = {
    JWT_KEY: 'learningPlan.jwt',
    JWT_REF_KEY: 'learningPlan.ref_jwt',
    JWT_EXP_KEY: 'learningPlan.exp_jwt',
    USER_NAME: 'learningPlan.full_user_name',
    USER_EMAIL: 'learningPlan.full_user_email'
};

//LOCAL
export const URLS = {
    
    // PROD
    REDIRECT : "https://analytics.bigbrain.com.br/",

    
    //LOCALHOST
    //REDIRECT : "http://localhost:44325/",
}

export const ADAL = {
    TENANT: "common",
    CLIENT_ID: "2a61922d-1ba7-4dc4-9a29-1bee8d5b7cb6",
    CLIENT_SECRET: "f917Q~nh8rLE0hM_VhXL7f0UXG0X0T~FocKU8"
}

export default {
    STORAGE_KEYS,
    URLS,
    ADAL
}
