import * as React from "react";
import { useEffect } from "react";
import UserInfo from "../core/UserInfo";
import { Helmet } from 'react-helmet'
import $ from "jquery";

const PagePowerBI = (commom) => {
  const [srcImg, setSrc] = React.useState();
  const [titlePage, setTitlePage] = React.useState("Relatórios Analíticos");

  useEffect(() => {
    switchRender();
  }, []);

  const getData = async (tenantID) => {
    var url = "https://provisionadorteams.bigbrain.com.br/api/primeiroacesso/getClientAnalytics/";
    var wait = await $.get(url, { tenantId: tenantID })
      .done(function (data) {
        return data
      });
    return wait
  };

  const switchRender = async () => {
    debugger
    let userInfo = new UserInfo();
    var getIframe = await getData(userInfo.Tenant);
    
    if (getIframe !== false) {
      setSrc(getIframe);
      setTitlePage("Relatórios Analíticos")
    }
  };

  return (
    <>
      <Helmet>
        <title>{titlePage}</title>
      </Helmet>
      <main className="h-100">
        <container fluid className="h-100 animated fadeIn">
          <iframe
            style={{ width: "100%", height: "100%" }}
            src={srcImg}
            title="POWER BI"
          ></iframe>
        </container>
      </main>
    </>
  );
};

export default PagePowerBI;
